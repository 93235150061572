import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

interface S {
  // Customizable Area Start
  expertiseArea:string
  countryExpertise:string
  language:string
  proficiency:string
  expertiseSkills :string []
  industryFocus:string
  industryExpertiseArray:string []
  services:string
  servicesArray:string[]
  selectedCountries: string[]
  expertiseAreaError:string
  countryExpertiseError:string
  industryFocusError: string
  languageError:string
  servicesError:string
  proficiencyError: any[]
  addLanguage:any[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class ExpertiseAndFeesFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      expertiseArea:'',
      countryExpertise:"",
      proficiency:"",
      industryFocus:"",
      language:"",
      services:"",
      expertiseSkills : [],
      industryExpertiseArray:[],
      servicesArray:[],
      selectedCountries: [],
      expertiseAreaError:"",
      countryExpertiseError:"",
      industryFocusError:"",
      languageError:"",
      servicesError:"",
      proficiencyError:[],
      addLanguage:[{
        language:"",
        proficiency:""
      }]
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
  }
  validateForm = () => {
    const { 
      expertiseArea,
      countryExpertise,
      industryFocus,
      services,
      language,
      proficiency
    } = this.state;
  
    let isValid = true;
  
    if (!expertiseArea) {
      this.setState({ expertiseAreaError: "* Please add your Area of Expertise" });
      isValid = false;
    } else {
      this.setState({ expertiseAreaError: "" });
    }
  
    if (!countryExpertise) {
      this.setState({ countryExpertiseError: "* Please select your Country Expertise" });
      isValid = false;
    } else {
      this.setState({ countryExpertiseError: "" });
    }
  
    if (!industryFocus) {
      this.setState({ industryFocusError: "* Please add your Industry Focus" });
      isValid = false;
    } else {
      this.setState({ industryFocusError: "" });
    }
  
    if (!services) {
      this.setState({ servicesError: "* Please add your Services" });
      isValid = false;
    } else {
      this.setState({ servicesError: "" });
    }

    return isValid;
  };

  handleNext = () => {
    if (this.validateForm()) {
      this.setState({
        expertiseAreaError:"",
        countryExpertiseError:"",
        industryFocusError:"",
        languageError:"",
      });
  
    }
  };
  handleback(){
    this.props.navigation.navigate( 'CustomFormExperience');

  }
  handleExpertiseInputChange = (event: { target: { value: any; }}) => {
    this.setState({ expertiseArea: event.target.value ,expertiseAreaError:""});
  };

  handleIndustryInputChange = (event: { target: { value: any; }}) => {
    this.setState({ industryFocus: event.target.value ,industryFocusError:""});
  };

  
  handleServicesInputChange = (event:{ target: { value: any; }} ) => {
    const selectedService = event.target.value as string;
    this.setState((prevState) => ({
      services: '',
      servicesError:"",
      servicesArray: prevState.servicesArray.includes(selectedService)
        ? prevState.servicesArray
        : [...prevState.servicesArray, selectedService],
    }));
  };
 
  handleLanguageChange = (index: number, event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    const addLanguage = [...this.state.addLanguage];
    addLanguage[index].language = value as string;
  
    if (value && !addLanguage[index].proficiency) {
      const proficiencyError = [...this.state.proficiencyError];
      proficiencyError[index] = true;
      this.setState({ addLanguage, proficiencyError });
    } else {
      const proficiencyError = [...this.state.proficiencyError];
      proficiencyError[index] = false;
      this.setState({ addLanguage, proficiencyError });
    }
  };
  
  handleProficiencyChange = (index: number, event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    const addLanguage = [...this.state.addLanguage];
    addLanguage[index].proficiency = value as string;
  
    if (value) {
      const proficiencyError = [...this.state.proficiencyError];
      proficiencyError[index] = false;
      this.setState({ addLanguage, proficiencyError });
    }
  
    this.setState({ addLanguage });
  };
  
  
  handleExpertiseKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && this.state.expertiseArea.trim() !== '') {
      this.setState((prevState) => ({
        expertiseSkills: [...prevState.expertiseSkills, prevState.expertiseArea.trim()],
        expertiseArea: '',
      }));
    }
  };

  handleIndustryKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && this.state.industryFocus.trim() !== '') {
      this.setState((prevState) => ({
        industryExpertiseArray: [...prevState.industryExpertiseArray, prevState.industryFocus.trim()],
        industryFocus: '',
      }));
    }
  };



  handleDeleteSkill = (skillToDelete: string) => {
    this.setState((prevState) => ({
      expertiseSkills: prevState.expertiseSkills.filter((skill) => skill !== skillToDelete),
    }));
  };

  handleDeleteIndustry = (industryToDelete: string) => {
    this.setState((prevState) => ({
      industryExpertiseArray: prevState.industryExpertiseArray.filter((industry) => industry !== industryToDelete),
    }));
  };

  handleDeleteService = (serviceToDelete: string) => {
    this.setState((prevState) => ({
      servicesArray: prevState.servicesArray.filter((service) => service !== serviceToDelete),
    }));
  };

  handleCountrySelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedCountry = event.target.value as string;
    this.setState((prevState) => ({
      countryExpertiseError:"",
      selectedCountries: prevState.selectedCountries.includes(selectedCountry)
        ? prevState.selectedCountries
        : [...prevState.selectedCountries, selectedCountry],
    }));
  };

  handleDeleteCountry = (countryToDelete: string) => {
    this.setState((prevState) => ({
      selectedCountries: prevState.selectedCountries.filter((country) => country !== countryToDelete),
    }));
  };

  handleAddLanguage = () => {
    const newLanguage = {
      language:"",
      proficiency:""
    };
    this.setState({ addLanguage: [...this.state.addLanguage, newLanguage] });
  }
  // Customizable Area End
}