import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
}

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    }
  }
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  dateOfBirth: any
  streetAddress:string
  suite:string
  additionalAddress:string
  country: string,
  city: string,
  state: string,
  zipCode: string,
  phone: string,
  jobTitle: string,
  skills: string,
  description: string,
  dateOfBirthFreelancerError:string
  streetAddressError:string
  countryFreelancerErr:string
  cityError:string
  jobTitleError:string
  phoneError:string
  skillsError:string
  descriptionError:string
  zipCodeError:string
  stateError:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      dateOfBirth: "",
      streetAddress:"",
      suite:"",
      additionalAddress:"",
      country: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
      jobTitle: "",
      skills: "",
      description: "",
      dateOfBirthFreelancerError:"",
      streetAddressError:"",
      countryFreelancerErr:"",
      cityError:"",
      jobTitleError:"",
      phoneError:"",
      skillsError:"",
      descriptionError:"",
      zipCodeError:"",
      stateError:""
      // Customizable Area End
    };

    // Customizable Area Start
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStreetAdressChange = this.handleStreetAdressChange.bind(this)
    this.handleSuiteChange = this.handleSuiteChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleZipCodeChange = this.handleZipCodeChange.bind(this)
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleJobTitleChange = this.handleJobTitleChange.bind(this);
    this.handleSkillsChange = this.handleSkillsChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
  }
  validateForm = () => {
    const { 
      dateOfBirth,
      streetAddress,
      country,
      city,
      state,
      phone,
      jobTitle,
      skills,
      description,
    } = this.state;
  
    let isValid = true;
  
    if (!dateOfBirth) {
      this.setState({ dateOfBirthFreelancerError: "* Date of birth is required" });
      isValid = false;
    } else {
      this.setState({ dateOfBirthFreelancerError: "" });
    }
  
    if (!streetAddress) {
      this.setState({ streetAddressError: "* Street address is required" });
      isValid = false;
    } else {
      this.setState({ streetAddressError: "" });
    }
  
    if (!country) {
      this.setState({ countryFreelancerErr: "* Country is required" });
      isValid = false;
    } else {
      this.setState({ countryFreelancerErr: "" });
    }
  
    if (!city) {
      this.setState({ cityError: "* City is required" });
      isValid = false;
    } else {
      this.setState({ cityError: "" });
    }
  
    if (!phone) {
      this.setState({ phoneError: "* Phone number is required" });
      isValid = false;
    } else {
      this.setState({ phoneError: "" });
    }
  
    if (!jobTitle) {
      this.setState({ jobTitleError: "* Job Title is required" });
      isValid = false;
    } else {
      this.setState({ jobTitleError: "" });
    }
  
    if (!skills) {
      this.setState({ skillsError: "* Skills are required" });
      isValid = false;
    } else {
      this.setState({ skillsError: "" });
    }
  
    if (!description) {
      this.setState({ descriptionError: "* Description is required" });
      isValid = false;
    } else {
      this.setState({ descriptionError: "" });
    }

    if (!state) {
      this.setState({ stateError: "* State is required" });
      isValid = false;
    } else {
      this.setState({ stateError: "" });
    }

    return isValid;
  };
  
  handleNext = () => {
    if (this.validateForm()) {
      this.setState({
        dateOfBirthFreelancerError:"",
        streetAddressError:"",
        countryFreelancerErr:"",
        cityError:"",
        jobTitleError:"",
        phoneError:"",
        skillsError:"",
        descriptionError:"",
        stateError:"",
      });
      this.props.navigation.navigate( 'CustomFormExperience');

  
    }
  };
  handleDateChange(event: { target: { value: any; }; }) {
    this.setState({ dateOfBirth: event.target.value, 
      dateOfBirthFreelancerError: "",
     });
  }
  handleStreetAdressChange(event: { target: { value: any; }; }){
    this.setState({streetAddress:event.target.value,
      streetAddressError: "",
    })
  }
  handleSuiteChange(event: { target: { value: any; }; }) {
    this.setState({ suite: event.target.value});
  }

  handleAddressChange(event: { target: { value: any; }; }) {
    this.setState({ additionalAddress: event.target.value,
      streetAddressError: "",
     });
  }
  handleCountryChange(event: { target: { value: any; }; }) {
    this.setState({ country: event.target.value,
      countryFreelancerErr: "",
     });
  }

  handleCityChange(event: { target: { value: any; }; }) {
    this.setState({ city: event.target.value,
      cityError: "",
     });
  }

  handleStateChange(event: { target: { value: any; }; }) {
    this.setState({ state: event.target.value,
     stateError: "",
     });
  }
  handlePhoneChange = (event: { target: { value: string; }; }) => {
    const phone = event.target.value;
    this.setState({ 
      phone,
      phoneError: this.validatePhone(phone) ? "" : "* Invalid phone number format"
    });
  };
  
  handleZipCodeChange = (event: { target: { value: string; }; }) => {
    const zipCode = event.target.value;
    this.setState({ 
      zipCode,
      zipCodeError: this.validateZipCode(zipCode) ? "" : "* Invalid zip code format"
    });
  };
  
  validatePhone = (phone: string) => {
    const phoneRegex = /^[0-9]{10}$/; 
    return phoneRegex.test(phone);
  };
  
  validateZipCode = (zipCode: string) => {
    const zipCodeRegex = /^[0-9]{6}$/; 
    return zipCodeRegex.test(zipCode);
  };
  

  handleJobTitleChange(event: { target: { value: any; }; }) {
    this.setState({ jobTitle: event.target.value,
      jobTitleError: "",
     });
  }

  handleSkillsChange(event: { target: { value: any; }; }) {
    this.setState({ skills: event.target.value,
      skillsError: "",
     });
  }

  handleDescriptionChange(event: { target: { value: any; }; }) {
    this.setState({ description: event.target.value,
      descriptionError:""
     });
  }
  // Customizable Area End
}